module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-152879645-1","cookieName":"gatsby-gdpr-google-analytics","anonymize":true,"allowAdFeatures":false},"googleTagManager":{"trackingId":"UA-152879645-1","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"facebookPixel":{"pixelId":"","cookieName":"gatsby-gdpr-facebook-pixel"},"tikTokPixel":{"pixelId":"","cookieName":"gatsby-gdpr-tiktok-pixel"},"hotjar":{"hjid":"","hjsv":"YOUR_HOTJAR_SNIPPET_VERSION","cookieName":"gatsby-gdpr-hotjar"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"String","short_name":"String","start_url":"/","background_color":"#293BDC","theme_color":"#293BDC","display":"browser","icon":"src/favicons/string_favicon_512x512.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"7bcf8446068a415322fe2fcd9129cd88"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://string.com.pl/"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
