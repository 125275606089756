import { createGlobalStyle } from "styled-components"
import archiaThin from "../assets/fonts/archia-thin-0.otf"
import archiaLight from "../assets/fonts/archia-light-0.otf"
import archiaRegular from "../assets/fonts/archia-regular-2.otf"
import archiaMedium from "../assets/fonts/archia-medium-0.otf"
import archiaSemiBold from "../assets/fonts/archia-semibold-0.otf"
import archiaBold from "../assets/fonts/archia-bold-1.otf"

const GlobalStyle = createGlobalStyle`
	
  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 100;
    src: url(${archiaThin}) format('opentype');
  }

  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 300;
    src: url(${archiaLight})format('opentype');
  }

  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 400;
    src: url(${archiaRegular}) format('opentype');
  }

  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 500;
    src: url(${archiaMedium}) format('opentype');
  }

  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 600;
    src: url(${archiaSemiBold}) format('opentype');
  }

  @font-face {
    font-family: 'Archia';
    font-display: block;
    font-style: normal;
    font-weight: 700;
    src: url(${archiaBold}) format('opentype');
  }
	
  html {
		overflow-x: hidden;
		overflow-y:  ${props => props.isMenuVisible ? "hidden" : "auto"};
	}
	body {
		width: 100%;
		padding: 0;
		margin: 0;
		background-color: ${({ theme }) => theme.colors.stringWhite};
		font-family: ${({ theme }) => theme.font.textFont}; 
	}
	
	button {
	font-family: ${({ theme }) => theme.font.textFont};
	}
	
	.wf-active body {
		visibility: visible;
		font-family: ${({ theme }) => theme.font.textFont};
  }

	*, *::before, *::after{
		box-sizing: border-box;
	}
	
	h1, h2, h3, h4, h5, h6 {
		font-family: ${({ theme }) => theme.font.headerFont};
		margin: 0; 
	}
	
	p {
		font-family: ${({ theme }) => theme.font.textFont};
		margin: 0;
	}
	
	ul {
		list-style-type: none;
		padding: 0;
		margin: 0;
	}
`

export default GlobalStyle;
